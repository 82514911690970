<template lang='pug'>
EventToProp(
  v-slot='{ prop: loading, updateProp: loadingUpdate }'
  @update:prop='$emit("loading")'
)
  v-hover(
    v-slot='{ hover }'
  )
    slot(
      :disabled='disabled'
      :hover='hover'
      :loading='loading'
      :loadingUpdate='loadingUpdate'
    )
      v-btn(
        :disabled='disabled'
        :loading='loading'
        @click='loadingUpdate(true)'
        color='primary'
        depressed
      )
        v-icon(
          left
        )
          template(
            v-if='hover'
          )
            | mdi-check
          template(
            v-else
          )
            | mdi-check-outline
        slot(
          name='label'
        )
          | Okay
</template>

<script>
import EventToProp from '@thomasphan/vue-components/src/components/EventToProp.vue';

export default
{
  components:
  {
    EventToProp
  },
  props:
  {
    disabled: { required: true }
  },
};
</script>